if (typeof String.prototype.trim !== 'function') {
    String.prototype.trim = function () {
        return this.replace(/^\s+|\s+$/g, '');
    }
}

if (typeof Array.prototype.forEach != 'function') {
    Array.prototype.forEach = function (callback) {
        for (var i = 0; i < this.length; i++) {
            callback.apply(this, [this[i], i, this]);
        }
    };
}


Number.prototype.toRad = function () {
    return this * Math.PI / 180;
}

function scrollToAnchor(aid) {
    var aTag = $(aid);
    $('html,body').animate({ scrollTop: aTag.offset().top }, 'fast');
}

// Globals
var map = null;
var markers = [];
var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
var jsonReturnObj = null;
var infowindow = null;  // Used by google map

$(function () {
    google.maps.Map.prototype.clearMarkers = function () {
        for (var i = 0; i < markers.length; i++) {
            markers[i].setMap(null);
        }
        markers = [];
    };
});


function coOrds(lat, lng) {
    this.lat = lat;
    this.lng = lng;
}

function addMarker(lat, lng, title, img, popup, pinBGColour) {

    var latlng = new google.maps.LatLng(lat, lng);
    var marker = null;
    if (img == "") {

        var pinColor = "48cfa7";        // Default sandyford colours
        // Use the global var
        if (typeof globalPinColour !== 'undefined') {
            pinColor = globalPinColour;
        }
        // Use passed value if available
        if (pinBGColour != undefined) {
            pinColor = pinBGColour;
        }

        var pinImage = new google.maps.MarkerImage("//chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|" + pinColor,
            new google.maps.Size(21, 34),
            new google.maps.Point(0, 0),
            new google.maps.Point(10, 34));

        marker = new google.maps.Marker({
            position: latlng,
            map: map,
            title: title,
            icon: pinImage
        });
    } else {
        marker = new google.maps.Marker({
            position: latlng,
            map: map,
            title: title,
            icon: img
        });
    }

    google.maps.event.addListener(marker, 'click', (function (marker) {
        return function () {

            // Zoom in on the pin
            //var latlng = new google.maps.LatLng(marker.lat, marker.lng);
            //map.setCenter(latlng);
            map.setZoom(17);

            // Popup the details
            var content = popup;
            infowindow.setContent(content);
            infowindow.open(map, marker);
            
        }
    })(marker));

    google.maps.event.addListener(infowindow, 'closeclick', function () {
        // The info window scrolls the pin to the middle on click. Set it back to viewing all pins on close
        fitMap();
    });

    markers.push(marker);
}

function deleteMarkers() {
    map.clearMarkers();
    markers = [];
}

function fitMap() {
    //  Create a new viewpoint bound
    var bounds = new google.maps.LatLngBounds();
    //  Go through each...
    markers.forEach(function (marker) {
        bounds.extend(marker.getPosition());
    });

    //  Fit these bounds to the map
    map.fitBounds(bounds);
    map.setCenter(bounds.getCenter());
}



function getUserPostcodeCaption(userData) {
    return "This is your current location for the postcode: " + userData.userPostcode.postcode;
}


if (!Array.prototype.every) {
    Array.prototype.every = function (fun) {
        var len = this.length;
        if (typeof fun != "function")
            throw new TypeError();

        var thisp = arguments[1];
        for (var i = 0; i < len; i++) {
            if (i in this &&
                !fun.call(thisp, this[i], i, this))
                return false;
        }

        return true;
    };
}



function getDistance(latLng1, latLng2) {
    var lat2 = parseFloat(latLng2.lat);
    var lon2 = parseFloat(latLng2.lng);
    var lat1 = parseFloat(latLng1.lat);
    var lon1 = parseFloat(latLng1.lng);

    var R = 3960; // Miles 
    //has a problem with the .toRad() method below.
    var x1 = lat2 - lat1;
    var dLat = x1.toRad();
    var x2 = lon2 - lon1;
    var dLon = x2.toRad();
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.cos(lat1.toRad()) * Math.cos(lat2.toRad()) *
                    Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;

    return d;
}





var dates = {
    convert: function (d) {
        // Converts the date in d to a date-object. The input can be:
        //   a date object: returned without modification
        //  an array      : Interpreted as [year,month,day]. NOTE: month is 0-11.
        //   a number     : Interpreted as number of milliseconds
        //                  since 1 Jan 1970 (a timestamp) 
        //   a string     : Any format supported by the javascript engine, like
        //                  "YYYY/MM/DD", "MM/DD/YYYY", "Jan 31 2009" etc.
        //  an object     : Interpreted as an object with year, month and date
        //                  attributes.  **NOTE** month is 0-11.
        return (
            d.constructor === Date ? d :
            d.constructor === Array ? new Date(d[0], d[1], d[2]) :
            d.constructor === Number ? new Date(d) :
            d.constructor === String ? new Date(d) :
            typeof d === "object" ? new Date(d.year, d.month, d.date) :
            NaN
        );
    },
    compare: function (a, b) {
        // Compare two dates (could be of any type supported by the convert
        // function above) and returns:
        //  -1 : if a < b
        //   0 : if a = b
        //   1 : if a > b
        // NaN : if a or b is an illegal date
        // NOTE: The code inside isFinite does an assignment (=).
        return (
            isFinite(a = this.convert(a).valueOf()) &&
            isFinite(b = this.convert(b).valueOf()) ?
            (a > b) - (a < b) :
            NaN
        );
    },
    inRange: function (d, start, end) {
        // Checks if date in d is between dates in start and end.
        // Returns a boolean or NaN:
        //    true  : if d is between start and end (inclusive)
        //    false : if d is before start or after end
        //    NaN   : if one or more of the dates is illegal.
        // NOTE: The code inside isFinite does an assignment (=).
        return (
             isFinite(d = this.convert(d).valueOf()) &&
             isFinite(start = this.convert(start).valueOf()) &&
             isFinite(end = this.convert(end).valueOf()) ?
             start <= d && d <= end :
             NaN
         );
    }
}
