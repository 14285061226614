$(function(){
    var topNavButton = $('.header__button--accessibility');

    topNavButton.one('click', function() {
        $(this).addClass('header__button--loading');
        $.getScript("https://usrwy.com/widget.js");
    })

    document.addEventListener("userway:render_completed", function() {
        topNavButton.removeClass('header__button--loading');
        // topNavButton.addClass('header__accessibility--open');
        UserWay.widgetToggle();

        topNavButton.click(function() {
            UserWay.widgetToggle();
            // $(this).toggleClass('header__accessibility--open');
        })

    });
})