var allowConsoleLog = true;


/* TINY MODAL */

/*!
 * jQuery Tiny Modal
 * @author: Cedric Ruiz
 * https://github.com/elclanrs/jquery.tiny.modal
 */
(function ($) {

    var _defaults = {
        buttons: ['Ok', 'Cancel'],
        title: 'Alert',
        html: '<p>Alert</p>',
        Ok: $.noop,
        Cancel: $.noop,
        onOpen: $.noop,
        onClose: $.noop,
        clickOutside: true
    };

    $.tinyModal = function (opts) {

        var o = $.extend({}, _defaults, opts),
            $overlay = $('<div class="tinymodal-overlay">').hide(),
            $modal = $('<div class="tinymodal-window">\
          <div class="tinymodal-title">'+ o.title + '<div class="tinymodal-close">&#215;</div></div>\
          <div class="tinymodal-content"></div>\
          \
          </div>').hide(),
            $el = $(o.html);
        $children = $el.children();

        $modal.find('.tinymodal-content').append($children);

        if (o.buttons.length) {
            $modal.find('.inner').append('<button>' + o.buttons.join('</button><button>') + '</button>');
        }

        function show() {
            $('body').width($('body').width()).css('overflow', 'hidden');
            $overlay.fadeIn('fast', function () {
                $modal.fadeIn('fast', o.onOpen);
            });
            $modal.css({
                marginLeft: -($modal.width() / 2) + 'px'
            });
        }

        function hide(callback) {
            $modal.fadeOut('fast', function () {
                $('body').css({ width: 'auto', overflow: 'auto' });
                $overlay.fadeOut('fast');
                if (typeof callback === 'function') callback();
                $el.append($children);
            });
        }

        $modal.find('.tinymodal-buttons button, .tinymodal-close').on('click', function (e) {
            var callback = $(this).text();
            hide(o[callback]);
        });

        $modal.find('.tinymodal-close').click(o.onClose);

        $modal.on('click', function (e) { e.stopPropagation(); });

        if (o.clickOutside) $overlay.on('click', hide);

        $('body').prepend($overlay.append($modal));
        show();
    };

}(jQuery));


function cl(logWhat) {
    if (allowConsoleLog) {
        console.log(logWhat);
    }
}


function scrollToAnchor(aid) {
    var aTag = $(aid);
    $('html,body').animate({ scrollTop: aTag.offset().top - 60 }, 'fast');
}


//Requires jquery-simple-pagination.js
//wrap this call + settings
function paginate(elId, container) {
    var per_page = 10;
    var page_nums_to_show = 10;

    // Allow the container to be somehting other than a ul, but default
    if (typeof container === 'undefined') { container = 'ul'; }

    var outer = $('#' + elId);

    //do it anyway to get the 1-N of N text (?)
    outer.simplePagination({
        pagination_container: container,
        items_per_page: per_page,
        number_of_visible_page_numbers: page_nums_to_show
    });

    //but hide the pagination and total if only one page
    if (outer.find(container).children().length <= per_page)
        outer.find('.pagination').hide();
}

$(function () {
    menu_trigger = $('.menu-trigger');
    nav = $('.nav-container');
    search_trigger = $('.search-trigger');
    search_box = $('.header__search-container');
    body = $('body');
    translate_button = $('.header__button--translate');
    translate_dropdown = $('.translate-list__dropdown');

    menu_trigger.click(function () {
        $(this).toggleClass('menu-trigger--active');
        nav.toggleClass('nav-container--active');
        body.toggleClass('no-scroll');
        translate_button.removeClass('header__button--active');
        translate_dropdown.removeClass('translate-list__dropdown--active');

        $('html, body').animate({
            scrollTop: $("header").offset().top
        }, 500);
    });

    search_trigger.click(function () {
        $(this).toggleClass('search-trigger--active');
        search_box.toggleClass('header__search-container--active');

        menu_trigger.removeClass('menu-trigger--active');
        nav.removeClass('nav-container--active');
        body.removeClass('no-scroll');
        
        translate_button.removeClass('header__button--active');
        translate_dropdown.removeClass('translate-list__dropdown--active');

        $('html, body').animate({
            scrollTop: $("header").offset().top
        }, 500);
    });

    search_box.click(function (event) {
        event.stopPropagation();
    });

    $(document).click(function (e) {
        if (!$(search_trigger, search_box).is(e.target)) {
            search_trigger.removeClass('search-trigger--active');
            search_box.removeClass('header__search-container--active');
        }
    });

    translate_button.on('click', function() {
        $(this).toggleClass('header__button--active');
        translate_dropdown.toggleClass('translate-list__dropdown--active');
        body.toggleClass('no-scroll--tablet');

        menu_trigger.removeClass('menu-trigger--active');
        nav.removeClass('nav-container--active');
        body.removeClass('no-scroll');

        $('html, body').animate({
            scrollTop: $("header").offset().top
        }, 500);
    });


    $('.lazy-load').Lazy({
        effect: 'fadeIn',
        effectTime: 1000
    });
    

});

$(function () {
    // Add class if edge
    if (/Edge/.test(navigator.userAgent)) {
        $('body').addClass('ie ie--edge');
    }

    // Add class if IE 11
    var is_ie11 = navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1;
    if (is_ie11) {
        $('body').addClass('ie ie--11');
    }

    // Add class if IE10
    var is_ie10 = navigator.appVersion.indexOf("MSIE 10") !== -1;
    if (is_ie10) {
        $('body').addClass('ie ie--10');
    }

    if (navigator.userAgent.indexOf("MSIE 9.0") > 0) {
        $('body').addClass('ie ie--old');
    }

    if (navigator.appVersion.indexOf("MSIE") !== -1) {
        if (parseInt(navigator.appVersion.charAt(0)) < 9) {
            $('body').addClass('ie ie--old');
        }
    }

//     var browser = {
//         isIe: function () {
//             return navigator.appVersion.indexOf("MSIE") != -1;
//         },
//         navigator: navigator.appVersion,
//         getVersion: function() {
//             var version = 999; // we assume a sane browser
//             if (navigator.appVersion.indexOf("MSIE") != -1)
//                 // bah, IE again, lets downgrade version number
//                 version = parseFloat(navigator.appVersion.split("MSIE")[1]);
//             return version;
//         }
//     };

// if (browser.isIe() && browser.getVersion() <= 9) {

//     $('body').addClass('ie ie--old');
// }

    // Add class if safari
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) {
        $('body').addClass('body--safari');
    }

    // Remove empty paragraphs
    $('p').filter(function(){ return $.trim(this.innerHTML)==="&nbsp;" }).remove();

    // $('.alert-close').click(function(){
    //     $('.alert-panel').hide();
    // })



});


$(function(){
    $('.accordion-row .teaser').click(function(){
        $(this).parent().toggleClass('accordion-row--active');
    });
});


function doSearch() {
    document.location.href = '/search-results/?q=' + $('#searchText').val();
}

$(document).ready(function () {
    $('#searchText').bind('keypress', function (e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            doSearch();
        }
    });
});

$(document).ready(function(){
    var main_nav = $('.main-nav');
    var header_exit = $('.header-top__exit');
    // var alert_panel_height = $('.alert-panel').height();
    // nav = $('.nav-container');

    if ($(window).width() > 992) {
        main_nav.sticky({
            topSpacing:0
        });
        main_nav.on('sticky-start', function() {
            header_exit.addClass('header-top__exit--sticky');
            main_nav.addClass('main-nav--sticky');
        });
        main_nav.on('sticky-end', function() {
            header_exit.removeClass('header-top__exit--sticky');
            main_nav.removeClass('main-nav--sticky');
        });
    }

    // if ($(window).width() < 992) {

    //     nav.css('margin-top', alert_panel_height);

    // }


});

$(document).ready(function(){
    // $(".owl-carousel").owlCarousel({
    //     items: 1,
    //     loop: true,
    //     dots: true,
    //     lazyLoad: true,
    //     autoplay: true,
    //     autoHeight: true,
    //     autoplayTimeout: 20000,
    //     autoplayHoverPause: true
    // });

    /* === Add aria labels to owl carousel buttons === */
    $('.owl-carousel').each(function() {
        //Find each set of dots in this carousel
        $(this).find('.owl-dot').each(function(index) {
            //Add one to index so it starts from 1
            $(this).attr('aria-label', index + 1);
        });
    });

    
  });